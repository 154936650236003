import React from "react";

const Contato = () => {
    return (
        <>
        <div className="container">
            <section>
                <h3><strong><center>
                    Olá, eu me chamo George Lucas sou um programador iniciante, estou aqui<p/>
                    para dizer que estou aberto a projetos voltados a ReactJS e Python<p/>
                    tenho jogos e programas feitos em flutter e csharp (unity) e estão disponiveis na Playstore.<p/>
                </center></strong></h3>
            </section>
        </div>

        </>
    );
}

export default Contato;

